import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import { v4 as uuidv4 } from 'uuid';
import { useContext, useState, useEffect, useRef } from "react";
import classnames from "classnames";
import Nav from "./components/Nav";
import Sidebar from "./components/Sidebar";
import ApplicationWorkflow from "./pages/ApplicationWorkflow";
import axios, { AxiosResponse } from "axios";
import NextSteps from "./pages/NextSteps";
import NotFound from "./pages/NotFound";
import ErrorBoundary from "./components/ErrorBoundary";
import ErrorOccurred from "./pages/ErrorOccurred";
import {identifyWithPhone} from "./utils/analytics";
import Loading from "./components/Loading";
import ExistingAccount from "./pages/ExistingAccount";
import GoogleAuth from "./pages/GoogleAuth";
import PlaidOAuth from "./pages/PlaidOAuth";
import {
  clearApplicationToken,
  clearIsCallbackFlow,
  confirmDashboardPage,
  getApplicationRefreshToken,
  getApplicationToken, isCallbackFlow,
  isOnboardingRefreshV2Enabled
} from "./utils/helpers";
import { DeviceDetailsContext } from "./utils/device-details-context";
import {
  APPLICATION_BASE_URL,
  LOGIN_URL,
  LOGOUT_URL,
  REFRESH_TOKEN,
  VALIDATE_TOKEN_ENDPOINT,
} from "./constants/urls.constants";
import Earnest from "./pages/Partners/Earnest";
import SenecaWomen from "./pages/Partners/SenecaWomen";
import Krowdfit from "./pages/Partners/Krowdfit";
import CustomersBank from "./pages/Partners/CustomersBank";
import Oppfi from "./pages/Partners/OppFI";
import EmailSignInWorkflow from "./pages/EmailSignInWorkflow";
import { Toaster } from 'react-hot-toast';
import {
  APP_DOWNLOAD_QR_CODE_PATH,
  ROUTE_404_PATH,
  ROUTE_APPLY_CARD_PATH,
  ROUTE_BASE_PATH,
  ROUTE_EMAIL_SINGN_IN_PATH,
  ROUTE_ERROR_PATH,
  ROUTE_EXISTING_ACCOUNT_PATH,
  ROUTE_GOOGLE_AUTH_CALLBACK_PATH,
  ROUTE_NEXT_STEPS_PATH,
  DASHBOARD_PATH,
  ROUTE_EMAIL_SIGNIN_CALLBACK_PATH,
  ADD_BANK_CALLBACK_PATH,
  PAYMENT_METHOD,
  PROFILE_PATH,
  EDIT_ADDRESS_PATH,
  CONTACT_US_PATH,
  AUTO_PAY,
  ROUTE_SINGN_IN_PATH,
  ROUTE_BASIC_DETAILS_PATH,
  ROUTE_VERIFY_EMAIL_OTP_PATH,
  DESERVE_CARD_OVERVIEW_PATH,
  SERVICING_PATH,
  SLM_SIGN_IN_LANDING_PAGE_PATH
} from "./constants/route-paths.constants";
import Dashboard from "./pages/Dashboard";
import EmailSignInAuth from "./pages/EmailSignInAuth";
import Payment from "./pages/Payment_Method";
import ProfileView from "./pages/ProfileView";
import EditAddress from "./pages/EditAddress";
import ContactUs from "./pages/ContactUs";
import Autopay from "./pages/Autopay";
import SignInByPhone from "./pages/SignInByPhone";
import CapureEmail from "./pages/CapureEmail";
import SignInVerifyEmailOtp from "./pages/SignInVerifyEmailOtp";
import { DeserveCardOverview } from "./pages/CardOverview/DeserveCardOverview";
import { AppQRCode } from "./pages/AppQRCode";
import Amc from "./pages/Partners/Amc";
import Logo from "./components/Logo";
import BlockFi from "./pages/Partners/BlockFi";
import CHA from "./pages/CHA";
import ClientStorage from "./utils/client-storage";
import MicroFrontend from "./MicroFrontend";
import SLMIgnite from "./pages/Partners/SLMIgnite";
import SLMIgniteFAQ from "./pages/Partners/SLMIgniteFAQ";
import NewPayments from './pages/NewPayments';
import { AccountContextProvider } from './components/AccountDataContext';
import useIdle from "./hooks/useIdleTimeout";
import { USER_INACTIVITY_TIMEOUT_IN_SECONDS } from "./utils/constants";

type Props = {
  partnerName: string
};

function ServicingDashboard({history}) {
  return (
    <div>
      <div className="home">
        <MicroFrontend history={history} host={window['appConfig'].SERVICING_HOST} name="Servicing" />
      </div>
    </div>
  );
}

function App(props: Props) {
  const appConfig = window['appConfig'];
  const [isValidToken, setIsValidToken] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);

  const {browser, os} = useContext(DeviceDetailsContext);

  function redirectToLoginUrl() {
    window.location.href = LOGIN_URL;
  }

  let isRootPage = useRef(window.location.pathname === ROUTE_BASE_PATH || window.location.pathname === SLM_SIGN_IN_LANDING_PAGE_PATH);
  let isDashboardPage = useRef(confirmDashboardPage());

  const formsLayoutClasses = `app-container x-sm:w-full container mx-auto w-screen min-h-screen flex 2xl:flex-row xl:flex-row lg:flex-row x-sm:flex-col-reverse sm:flex-col-reverse md:flex-row x-sm:justify-end sm:justify-end`;

  const contentContainerClasses = `main-container x-sm:w-full mx-auto flex flex-col place-content-center x-sm:px-5 md:px-0 pb-5`;
  const dashboardContainerClasses = `container-dashboard x-sm:w-full`;

  useEffect(() => {
    ClientStorage.setLocal("session_trace_id", uuidv4());
    isRootPage.current = window.location.pathname === "/";
  }, []);

  function refreshAccessToken() {
    axios
      .post(
        REFRESH_TOKEN,
        {
          refresh_token: getApplicationRefreshToken(),
          device_details: {
            device_id: ClientStorage.getLocal("CUSTOMER_AUTH_LOGIN_IDENTIFIER"),
            model: os,
            name: browser,
          },
        },
        {
          headers: {
            Authorization: `Bearer ${getApplicationToken()}`,
          },
        }
      )
      .then((res) => {
        ClientStorage.setLocal("application_token", res.data.id_token);
        ClientStorage.setLocal(
          "application_refresh_token",
          res.data.refresh_token
        );

        setIsLoaded(true);
        setIsValidToken(true);
      })
      .catch((err) => redirectToLoginUrl());
  }

  function validateToken(applicationToken) {
    return axios
      .post(VALIDATE_TOKEN_ENDPOINT, {
        id_token: applicationToken,
      })
      .then((res) => {
        if (isCallbackFlow()) {
          let phoneNumber = ClientStorage.getLocal("non_cached_phone_number")


          identifyWithPhone(res.data.data["email"], phoneNumber);

          clearIsCallbackFlow();
        }
        setIsValidToken(true);
        setIsLoaded(true);
      })
      .catch((err) => {
        refreshAccessToken();
      });
  }

  function AuthRoute({ component: Component, ...rest }) {
    let { pathname } = window.location;
    let applicationToken = getApplicationToken();
    validateToken(applicationToken);

    const logout = () => {
      axios.get(LOGOUT_URL)
      .then((resp: AxiosResponse | any) => {
        clearApplicationToken();
        window.location.href = LOGIN_URL;
      })
    }
  
    useIdle({ onIdle: logout, idleTime: USER_INACTIVITY_TIMEOUT_IN_SECONDS })

    return (
      <Route
        {...rest}
        render={(props) =>
          !isLoaded || !isValidToken 
            ? <Loading /> 
            : pathname === DASHBOARD_PATH || pathname === EDIT_ADDRESS_PATH || pathname === CONTACT_US_PATH || pathname === PROFILE_PATH
              ? <AccountContextProvider>
                  <Component {...props} />
                  {renderSidebar()}
                </AccountContextProvider>
              : <Component {...props} />
        }
      />
    );
  }

  // TODO: [refactor̉] use single /apply-card route below, and add nested routes in it
  // add "/" as home page route

  const renderLogo = () => {
    let { pathname } = window.location;
    if(
      pathname !== ROUTE_BASE_PATH &&
      pathname !== SLM_SIGN_IN_LANDING_PAGE_PATH &&
      pathname !== PROFILE_PATH &&
      pathname !== DASHBOARD_PATH &&
      pathname !== EDIT_ADDRESS_PATH &&
      pathname !== AUTO_PAY &&
      pathname !== CONTACT_US_PATH &&
      pathname !== PAYMENT_METHOD
    ){
      return (
        <div className={`absolute left-1 sm:left-4 md:left-12 top-5`}>
          <Logo />
        </div>
      );
    }
    return null;
  }

  function renderNav() {
    let { pathname } = window.location;

    if (
      pathname !== DASHBOARD_PATH &&
      pathname !== PAYMENT_METHOD &&
      pathname !== ROUTE_BASE_PATH &&
      pathname !== EDIT_ADDRESS_PATH &&
      pathname !== CONTACT_US_PATH &&
      pathname !== PROFILE_PATH &&
      pathname !== AUTO_PAY &&
      !isOnboardingRefreshV2Enabled()
    ) {
      return <Nav partnerName={props.partnerName} />;
    } else {
      return null;
    }
  }

  function renderSidebar() {
    return isValidToken && <Sidebar partnerName={props.partnerName} />;
  }

  function getComponent(partnerName: string) {
    switch (partnerName) {
      case "sw":
        return SenecaWomen;
      case "oppfi":
        return Oppfi;
      case "krowdfit":
        return Krowdfit;
      case "customers_bank":
        return CustomersBank;
      case "amc":
        return Amc;
      case "blockfi":
        return BlockFi;
      case "slm_ignite":
        return window['appConfig'].ENABLE_SLM_IGNITE_SIGN_IN_PAGE === 'true' ?  SLMIgnite : SLMIgniteFAQ;
      default:
        return Earnest;
    }
  }

  /*
    TODO: refactor route paths, keep all /apply-card in one container with its classes
    don't check window.location.pathname to render layout
  */
  return (
    <Router>
      <Switch>
        {appConfig.PARTNER_NAME === "deserve" && (
          <Route
            exact
            path={DESERVE_CARD_OVERVIEW_PATH}
            component={DeserveCardOverview}
          />
        )}

        <div
          className={classnames({
            [formsLayoutClasses]:
              !isRootPage.current && !isDashboardPage.current,
            [`app-container-${appConfig.PARTNER_NAME}`]:
              appConfig.PARTNER_NAME === "amc" ||
              isOnboardingRefreshV2Enabled(),
          })}
        >
          <ErrorBoundary>
              <div
                className={classnames({
                  [contentContainerClasses]:
                    !isRootPage.current && !isDashboardPage.current,
                  [dashboardContainerClasses]: isDashboardPage.current,
                })}
              >
                <Switch>
                  {appConfig.ENVIRONMENT === "sandbox" && (
                    <Route
                      path={SERVICING_PATH}
                      exact
                      component={ServicingDashboard}
                    />
                  )}
                  {props.partnerName === "slm_ignite" && (
                    <Route
                      path={SLM_SIGN_IN_LANDING_PAGE_PATH}
                      exact
                      component={SLMIgnite}
                    />
                  )}
                  <Route
                    path={ROUTE_BASE_PATH}
                    exact
                    component={getComponent(props.partnerName)}
                  />
                  <Route
                    path={ROUTE_EMAIL_SINGN_IN_PATH}
                    component={EmailSignInWorkflow}
                  />
                  <Route path={ROUTE_SINGN_IN_PATH} component={SignInByPhone} />
                  <Route
                    path={ROUTE_EMAIL_SIGNIN_CALLBACK_PATH}
                    component={EmailSignInAuth}
                  />
                  <AuthRoute
                    exact
                    path={ROUTE_NEXT_STEPS_PATH}
                    component={NextSteps}
                  />
                  <AuthRoute
                    exact
                    path={DASHBOARD_PATH}
                    component={() => (
                      <Dashboard partnerName={props.partnerName} />
                    )}
                  />
                  <AuthRoute
                    exact
                    path={PAYMENT_METHOD}
                    component={() => {
                    if(window['appConfig'].ENABLE_NEW_PAYMENTS_METHOD_UI === 'true') {
                      return <NewPayments partnerName={props.partnerName} />
                    }
                    return <Payment partnerName={props.partnerName} />
                  }}
                  />
                  <AuthRoute
                    exact
                    path={PROFILE_PATH}
                    component={() => (
                      <ProfileView partnerName={props.partnerName} />
                    )}
                  />
                  <AuthRoute
                    exact
                    path={AUTO_PAY}
                    component={() => <Autopay partnerName={props.partnerName} />}
                  />
                  <AuthRoute
                    exact
                    component={() => (
                      <EditAddress partnerName={props.partnerName} />
                    )}
                    path={EDIT_ADDRESS_PATH}
                  />
                  <Route
                    path={ROUTE_GOOGLE_AUTH_CALLBACK_PATH}
                    component={GoogleAuth}
                  />
                  <Route
                    path={ROUTE_EXISTING_ACCOUNT_PATH}
                    component={ExistingAccount}
                  />
                  <Route
                    path={ROUTE_BASIC_DETAILS_PATH}
                    component={CapureEmail}
                  />

                  <Route
                    path={ROUTE_VERIFY_EMAIL_OTP_PATH}
                    component={SignInVerifyEmailOtp}
                  />

                  <AuthRoute
                    exact
                    component={ApplicationWorkflow}
                    path={ROUTE_APPLY_CARD_PATH}
                  />
                  <AuthRoute
                    exact
                    component={() => (
                      <ContactUs partnerName={props.partnerName} />
                    )}
                    path={CONTACT_US_PATH}
                  />

                  {/* For testing purpose */}
                  <AuthRoute
                    exact
                    component={AppQRCode}
                    path={APP_DOWNLOAD_QR_CODE_PATH}
                  />
                  {/* For testing purpose */}
                  <Route
                    path={ADD_BANK_CALLBACK_PATH}
                    component={() => (
                      <PlaidOAuth redirectURi={APPLICATION_BASE_URL} />
                    )}
                  />

                  <Route exact path={ROUTE_ERROR_PATH}>
                    <ErrorOccurred />
                  </Route>
                  <Route path={ROUTE_404_PATH}>
                    <NotFound />
                  </Route>
                  <Redirect from="*" to={ROUTE_404_PATH} />
                </Switch>
              </div>
              {renderNav()}
            {isOnboardingRefreshV2Enabled() && renderLogo()}
          </ErrorBoundary>
        </div>
      </Switch>
      <Toaster />
    </Router>
  );
}

export default App;
